import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button, Autocomplete, TextField, ToggleButtonGroup, ToggleButton } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { getAllCompany } from "../../store/actions/company";
import { useDispatch, useSelector } from "react-redux";
import PermissionComp from "../../components/permissionComp/permissionComp";
import { getAllRFTSByPharmacy } from "../../store/actions/rfts";
import details from '../../assets/details.png'
import moment from "moment";
import ModalButton from "../../components/modalButton/modalButton";
import RequestReceiveForm from "./requestReceiveForm";

const RequestReceived = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate()

    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [filterValue, setfilterValue] = useState('');
    const [days, setDays] = React.useState('today');

    const { user } = useSelector(state => state.auth)

    const handleChange = (newDays) => {
        setDays(newDays.target.value);
    };

    const tableHeader = ['Pharmacy Name', 'Request Date', 'Request Time', 'Status']
    const verifyParam = ['pharmacyName', 'requestDate', 'requestTime', 'statusName']

    const filter = [
        {
            id: 1,
            name: 'Pending'
        },
        {
            id: 2,
            name: 'Approved'
        }
    ]

    useEffect(() => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLRFTSBYWEARHOUSEANDPHARMACY",
            "data": { "WearHouse_ID": user?.locationID },
            "debug": null,
            "token": null
        }
        dispatch(getAllRFTSByPharmacy(data)).then(res => {
            setRequests(res.payload.data.reverse().map(ele => ({
                ...ele,
                requestDate: (moment(ele.rftsDate).format("YYYY-MM-DD")),
                requestTime: (moment(ele.rftsDate).format("h:mm a"))
            })))
            setIsLoading(false)
        })
    }, [])


    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Requests</Typography>
                <ToggleButtonGroup
                    color="secondary"
                    value={days}
                    exclusive
                    onChange={(e) => setDays(e.target.value)}
                    aria-label="Platform"
                >
                    <ToggleButton value="all">All</ToggleButton>
                    <ToggleButton value="today">Today</ToggleButton>
                </ToggleButtonGroup>

                <Box sx={{ width: '300px' }}>
                    <Autocomplete
                        disablePortal
                        options={filter}
                        size="small"
                        getOptionLabel={(option) => {
                            return filter.find(e => (e.name == option.name))?.name || ""
                        }}
                        onChange={(_, data) => setfilterValue(data?.name)}
                        renderInput={(params) => <TextField {...params} label="Status" />}
                    />
                </Box>
            </Box>
            <Card style={{ flex: 1, overflow: 'auto' }}>
                <TableContainer
                    tableHeader={tableHeader}
                    data={requests.filter(val => {
                        const isStatusMatch = !filterValue || val.statusName === filterValue;
                        const isTodayMatch = days === 'all' || val.requestDate === moment().format("YYYY-MM-DD");
                        return isStatusMatch && isTodayMatch;
                    })}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    action={TableAction} />
            </Card>
        </>
    );
};

export default RequestReceived;



const TableAction = ({ id }) => {
    const navigation = useNavigate()
      const [openEdit, setOpenEdit] = React.useState(false);

    return (
        <Box>
            <ModalButton open={openEdit} setOpen={setOpenEdit} tooltip={'Request Received'} img={details} type={'icon'}>
                    <RequestReceiveForm state={id} setOpen={setOpenEdit} />
                </ModalButton>
            {/* <Tooltip title="Request Received">
                <IconButton onClick={() => navigation('requestReceived', { state: id })} sx={{ width: '40px', margin: '0px auto' }}>
                    <img src={details} style={{ width: '25px', height: '25px', objectFit: 'contain' }} />
                </IconButton>
            </Tooltip> */}
        </Box>
    )
}
import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button, Grid } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllPo, getAllPoDetail } from "../../store/actions/po";

import details from '../../assets/details.png'
import PermissionComp from "../../components/permissionComp/permissionComp";
import moment from "moment";
import ModalButton from "../../components/modalButton/modalButton";
import PoDetailForm from "./poDetailForm";
import { filterName } from "../../utils/Utils";

const PoDetail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const { poDetail } = useSelector(state => state.po)
    const { vendorList, packSizeList, medicationTypeList } = useSelector(state => state.filler)


    const [open, setOpen] = React.useState(false);

    const location = useLocation();
    const { state } = location

    const [PoDetail, setPoDetail] = useState([])

    const tableHeader = ['DIN Number', 'Product Name', 'Type of Medication', 'Strength', 'Pack Size', 'Quantity', 'Price']
    const verifyParam = ['diN_Master', 'productName', 'dosageValue', 'mg', 'packSizeValue', 'qty', 'price']

    useEffect(() => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLPODETAIL",
            "data": { "PO_ID": state.id },
            "debug": null,
            "token": null
        }
        dispatch(getAllPoDetail(data)).then(res => {
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {
        let arr = []
        poDetail.forEach((ele) => {
            const obj = { ...ele }
            obj['isActive'] = obj.isActive ? "Active" : obj.isActive ? "Non-Active" : '';
            obj['vendorValue'] = filterName(vendorList, obj.vendor_ID);
            obj['dosageValue'] = filterName(medicationTypeList, obj.dosage_ID);
            obj['packSizeValue'] = filterName(packSizeList, obj.packSize_ID);
            arr.push(obj)
        })
        setPoDetail(arr)

    }, [poDetail])

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Purchase Order Detail</Typography>
                {state.poDate == moment(new Date()).format('YYYY-MM-DD') &&
                    <PermissionComp list={['PO', 'detail', 'create']}>
                        <ModalButton open={open} setOpen={setOpen} tooltip={'Products'} type={'Button'}>
                            <PoDetailForm poID={state.id} vendorID={state.vendor_ID} setOpen={setOpen} />
                        </ModalButton>
                    </PermissionComp>}
            </Box>
            <Card style={{ flex: 1, overflow: 'auto' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <Typography variant="h4">PO No:</Typography>
                        <Typography variant="body1" sx={{ marginLeft: '10px', textWrap: 'wrap' }}>{state.poNo}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <Typography variant="h4">Vendor Name:</Typography>
                        <Typography variant="body1" sx={{ marginLeft: '10px', textWrap: 'wrap' }}>{filterName(vendorList, state.vendor_ID)}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                        <Typography variant="h4">PO Date:</Typography>
                        <Typography variant="body1" sx={{ marginLeft: '10px', textWrap: 'wrap' }}>{state.poDate}</Typography>
                    </Grid>
                </Grid>
                <TableContainer tableHeader={tableHeader}
                    data={PoDetail}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                // action={TableAction} 
                />
            </Card>
        </>
    );
};

export default PoDetail;



const TableAction = (data) => {
    const navigation = useNavigate()

    return (

        <Box>
            <IconButton onClick={() => navigation('detail')} sx={{ width: '40px', margin: '0px auto' }}>
                <img src={details} style={{ width: '25px', height: '25px', objectFit: 'contain' }} />
            </IconButton>
        </Box>
    )
}
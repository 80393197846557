import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, CircularProgress, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import './po.scss'
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createPo, getOtherVendorsList } from "../../store/actions/po";
import { filterName, setDataObject } from "../../utils/Utils";
import { getAllProduct } from "../../store/actions/product";
import add from '../../assets/add.png';
import TableContainer from "../../components/table/table";
import SelectedProduct from "../../components/selected";
import FilterBar from "../../components/filterBar/filterBar";
import SaveButton from "../../components/saveButton/saveButton";
import { useForm } from "react-hook-form";
import ModalButton from "../../components/modalButton/modalButton";
import InputFields from "../../components/InputFields";
import SelectFields from "../../components/SelectFields";
import ButtonContainer from "../../components/buttonContainer";
import Expandable from "../../components/expandable/expandable";
import { useTheme } from "@emotion/react";

const POOtherVendors = (props) => {
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const theme = useTheme()

    const { orderListDetail, orderList, vendorID } = props
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [otherVendorList, setOtherVendorList] = useState([])
    const { vendorList } = useSelector(state => state.filler)

    useEffect(() => {
        if (open) {
            checkOtherVendors()
        }
    }, [open])

    const selectedHeader = ['DIN Number', 'Product Name', 'Vendor', 'Strenght', 'Quantity']
    const selectedParam = ['diN_Master', 'productName', 'vendorValue', 'mg', 'productQty']


    const checkOtherVendors = () => {
        setIsLoading(true)
        let arr = orderList.map(e => {
            let productDetail = orderListDetail.find(val => val.id == e.ProductId)
            return ({
                "ProductName": productDetail.productName?.trim(),
                "Strength": productDetail.mg,
                "Vendor_ID": vendorID,
                "PageIndex": null,
                "PageSize": null
            })
        })
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETPRODUCTAVAILABILITY",
            "data": arr,
            "debug": null,
            "token": null
        }
        dispatch(getOtherVendorsList(data)).then(res => {
            console.log(res.payload?.data)
            let arr = []
            res.payload?.data.forEach((ele) => {
                const obj = { ...ele }
                obj['vendorValue'] = filterName(vendorList, obj.vendor_ID)
                arr.push(obj)
            })
            setOtherVendorList(arr)
            setIsLoading(false)
        })
    }

    return (
        <ModalButton open={open} setOpen={setOpen} tooltip={'Check other Vendor'}>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column' }}>
                {
                    isLoading ? <CircularProgress size={50} sx={{ margin: '20px' }} /> :
                        <>
                            {
                                orderList.map(item => {
                                    let productDetail = orderListDetail.find(val => val.id == item.ProductId)
                                    const filteredVendors = otherVendorList.filter(val =>
                                        val.productName?.trim() === productDetail?.productName?.trim() && val?.mg == productDetail?.mg
                                    );
                                    return (
                                        <Expandable title={`${productDetail.productName} ${productDetail.mg}`}>
                                            {filteredVendors.length > 0 &&
                                                <><Grid container spacing={1} sx={{ padding: '10px 20px' }}>
                                                    {selectedHeader.map(e => <Grid xs={12 / selectedHeader.length}>
                                                        <Typography>{e}</Typography>
                                                    </Grid>)}
                                                </Grid>
                                                    <Divider sx={{ margin: '10px 0px' }} />
                                                </>
                                            }
                                            {filteredVendors.length > 0 ? (
                                                filteredVendors.map((vendor, i) => (
                                                    < Grid
                                                        container
                                                        spacing={1}
                                                        sx={{
                                                            background: i % 2 ? theme.palette.rowBackground : 'transparent',
                                                            padding: '0px 20px'
                                                        }}
                                                        key={i}
                                                    >
                                                        {selectedParam.map(p => <Grid xs={12 / selectedHeader.length} sx={{ margin: '10px 0px' }}>
                                                            <Typography>{vendor[p]}</Typography>
                                                        </Grid>)}

                                                    </Grid >
                                                ))
                                            ) : (
                                                <Typography sx={{ padding: '10px', textAlign: 'center' }}>No vendors available</Typography>
                                            )}
                                        </Expandable>
                                    )
                                })
                            }

                        </>
                }
            </Box>

        </ModalButton >
    )
}

export default POOtherVendors;

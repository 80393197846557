import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import { IoIosSearch } from "react-icons/io";
import { IconButton, TextField } from '@mui/material';
import Input from '@mui/material/Input';
import './searchBar.scss'
import { useTheme } from '@emotion/react';

export default function SearchBar(props) {
    const theme = useTheme();
    const { placeholder, handleChange, disabled } = props

    const [search, setSearch] = React.useState('')

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleChange(search);
        }
    };

    return (
        <Box className="searchBarContainer">
            <Input
                style={{ width: '70%', margin: '0px 10px' }}
                placeholder={placeholder}
                disableUnderline
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={disabled}
            />
            <IconButton onClick={() => handleChange(search)} className='iconContainer' style={{ background: theme.palette.secondary.main }}>
                <IoIosSearch color='#fff' size={25} />
            </IconButton>
        </Box>
    );
}